import React, { useState, useEffect, useRef, useCallback } from "react";

import { projects } from "../assets/js/projects";
import ProjectCard from "../components/home/project_card";
import { motion } from "framer-motion";
const ProjectsPage = () => {
    const [visibleProjects, setVisibleProjects] = useState(9);
    const sentinelRef = useRef(null);

    const loadMoreProjects = useCallback(() => {
        if (visibleProjects < projects.length) {
            setVisibleProjects(prev => prev + 9);
        }
    }, [visibleProjects]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    loadMoreProjects();
                }
            },
            { threshold: 0.1 }
        );

        const currentSentinel = sentinelRef.current;
        if (currentSentinel) {
            observer.observe(currentSentinel);
        }
        return () => {
            if (currentSentinel) {
                observer.unobserve(currentSentinel);
            }
        };
    }, [loadMoreProjects]);
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
    }, []);
    return (
        <motion.div
            initial={{ opacity: 0, }}
            animate={{ opacity: 1, }}
            className="pt-10" id="projects"
        >
            <div className="container md:px-6 flex flex-col items-center">
                <h3 className="text-4xl font-bold text-uzmoon-500 mb-1 font-['Inter'] title">Projects</h3>
                <p className="text-xl font-light text-uzmoon-500 mb-6 font-['Inter']">A few selected projects showcasing our creativity and expertise</p>
                <div className="h-1 w-24 mb-6" style={{ background: 'black' }}></div>
                <div className='md:w-[70%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-center justify-between gap-x-5 gap-y-5'>
                    {projects.slice(0, visibleProjects).map((project, index) => (
                        <div key={index} data-aos="fade-up">
                            <ProjectCard
                                index={index}
                                name={project.name}
                                logo={project.logo}
                                photo={project.photo}
                                description={project.description}
                                langs={project.langs}
                                style={index % 2}
                            />
                        </div>
                    ))}
                    <span ref={sentinelRef} className="col-span-full w-full"></span>
                </div>
                <div className="md:w-[70%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-center justify-between gap-x-5 gap-y-5">
                    <div className="border-[1px] border-uzmoon-color rounded-[1%]" data-aos="fade-up">
                        <div className="w-full flex items-center justify-center overflow-hidden border-b-[1px] border-uzmoon-color">
                            <img alt="Project Add" src="/assets/images/projects/addproject.png" className="object-cover transition duration-300 ease-in-out hover:scale-110 h-[10rem] object-top w-full" />
                        </div>
                        <div className="p-3">
                            <div>
                                <div>
                                    <div className="font-['SpaceGrotesk'] text-[17px] font-bold uppercase text-main-color">
                                        Add New Project +
                                    </div>
                                    <div className="font-['Inter'] text-[12px] text-uzmoon-color">
                                        Click here to add a new project to your list of projects.
                                    </div>
                                </div>
                            </div>
                            <div className="pt-2 font-['Inter'] text-[10px] lowercase text-uzmoon-color overflow-x-auto" style={{ scrollbarWidth: 'none' }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default ProjectsPage;
