export const projects = [
  {
    name: "Yks Yardımcı",
    photo: "yks.png",
    description: "Mobile App",
    loog: 'yksyardimci.png',
    langs: ["Flutter", "Mysql", "Php"],
  },
  {
    name: "Uni Prizren",
    photo: "unipz.gif",
    description: "University Mobile App",
    langs: ["Flutter", "Mysql", "Php"],
  },

  {
    name: "Gazi Çiğköfte",
    photo: "gazicigkofte.png",
    description: "Food",
    langs: ["Mysql", "Php", "Uz Csm", "bootstrap"],
  },

  {
    name: "Mehran Automotive",
    photo: "mehran_automotive.gif",
    description: "Automotive",
    langs: ["Mysql", "Php", "Uz Csm", "bootstrap"],
  },
  {
    name: "Wolrider",
    photo: "wolrider.png",
    description: "Personal",
    langs: ["Mysql", "Php", "tailwindcss"],
  },
  {
    name: "Web Odası",
    photo: "webodasi.png",
    description: "News - Blog",
    langs: ["wordpress"],
  },
  {
    name: "Bay Shopping",
    photo: "bay.png",
    description: "E Commerce",
    loog: 'bayshopping.png',
    langs: ["Wordpress", "Woocommerce", "Mysql", "Php"],
  },
  {
    name: "Plazakurdu",
    photo: "plazakurdu.png",
    description: "Real Estate",
    langs: ["Drupal", "Mysql", "Php"],
  },
  {
    name: "Haber Sam",
    photo: "habersam.gif",
    description: "News",
    langs: ["Cordova", "Firebase", "Php", "Mysql"],
  },
  {
    name: "Farge Yapi",
    photo: "fargeyapi.png",
    description: "Construction",
    langs: ["Mysql", "Php"],
  },

  {
    name: "Log Ks",
    photo: "logks.png",
    description: "Logistic",
    langs: ["Mysql", "Php"],
  },
  {
    name: "Amz Programs",
    photo: "amzprograms.png",
    description: "Artificial Intelligence",
    langs: ["Mysql", "Php"],
  },
  {
    name: "Agona Shporta",
    photo: "agona.png",
    description: "Music Group",
    langs: ["Mysql", "Php"],
  },
  {
    name: "Dage",
    photo: "dage.png",
    description: "Curtain",
    langs: ["Mysql", "Php", "Uz Csm", "tailwindcss"],
  },
  {
    name: "Imtiy Az",
    photo: "imtiyaz.png",
    description: "Product Control",
    langs: ["Mysql", "Php", "Uz Csm", "tailwindcss"],
  },
  {
    name: "Gır Gır",
    photo: "girgir.png",
    description: "Social Media",
    langs: ["Mysql", "Php", "Uz Csm", "tailwindcss"],
  },
  {
    name: "Kosovo Bus",
    photo: "kosovobus.png",
    description: "Mobile App",
    langs: ["Flutter", "Mysql", "Php"],
  },
  {
    name: "Dora Dores",
    photo: "doradores.gif",
    description: "Mobile App",
    langs: ["Flutter"],
  },

  {
    name: "Abi Progres",
    photo: "abiprogres.png",
    description: "Food Business",
    langs: ["Flutter", "Mysql", "Php", "Uz Csm"],
  },

  {
    name: "Vaktia e Ramazanit",
    photo: "ramazanit.png",
    description: "Mobile App",
    langs: ["Flutter", "Mysql", "Php", "Uz Csm"],
  },
  {
    name: "Learn German",
    photo: "german.gif",
    description: "Mobile App",
    langs: ["Flutter"],
  },
  {
    name: "Abi Platinum",
    photo: "abiplatinum.png",
    description: "Building",
    langs: ["Flutter", "Mysql", "Php", "Uz Csm"],
  },
  {
    name: "Color Db",
    photo: "colordb.png",
    description: "Mobile Game",
    langs: ["Cordova"],
  },

  {
    name: "Forever Linux",
    photo: "foreverlinux.png",
    description: "Mobile App",
    langs: ["Kodular", "Firebase"],
  },

  {
    name: "Today In History",
    photo: "todayinhistory.png",
    description: "Mobile App",
    langs: ["Cordova", "Firebase", "php", "mysql", "javascript"],
  },

  {
    name: "Abi Green Hotel",
    photo: "abigreenhotel.png",
    description: "Hotel Portfolio",
    langs: ["Flutter", "Mysql", "Php", "Uz Csm"],
  },
  {
    name: "Cube Cut",
    photo: "cubecut.png",
    description: "Mobile Game",
    langs: ["Cordova", "javascript"],
  },
  {
    name: "Stars City Life",
    photo: "starscitylife.png",
    description: "Real Estate",
    langs: ["Mysql", "Php", "tailwindcss"],
  },

  {
    name: "Dogan Kirkul",
    photo: "dogankirkul.png",
    description: "Personal",
    langs: ["Mysql", "Php", "Uz Csm", "bootstrap"],
  },

  {
    name: "Özçelik Turizm",
    photo: "ozcelik.png",
    description: "Tourism",
    langs: ["Mysql", "Php", "tailwindcss"],
  },
  {
    name: "Radio Helix",
    photo: "radiohelix.png",
    description: "Radio",
    langs: ["Mysql", "Php", "Uz Csm", "bootstrap"],
  },

  {
    name: "Protocall",
    photo: "protocall.png",
    description: "Call Center",
    langs: ["Mysql", "Php", "tailwindcss"],
  },
  {
    name: "Coptris",
    photo: "coptris.png",
    description: "Mobile Game",
    langs: ["Cordova", "Firebase"],
  },

  {
    name: "Lazkar",
    photo: "lazkar.png",
    description: "Construction and Building",
    langs: ["Php", "Mysql", "Bootstrap"],
  },

  {
    name: "XTech Task Traking",
    photo: "tasktraking.png",
    description: "Task Traking App",
    langs: ["Flutter", "Mysql", "Php", "Uz Csm"],
  },
  {
    name: "Abi Milk",
    photo: "abimilk.png",
    description: "Milk factory",
    langs: ["Flutter", "Mysql", "Php", "Uz Csm"],
  },
  {
    name: "Nam Hotel",
    photo: "namhotel.png",
    description: "Hotel Booking",
    langs: ["Mysql", "Php", "Uz Csm"],
  },
  {
    name: "Kelime Avı",
    photo: "kelimeavi.gif",
    description: "Game ",
    langs: ["Mysql", "Php", "Uz Csm", "Flutter"],
  },
  {
    name: "Youtube Mp3 Downloader",
    photo: "youtube-mp3-downloader.png",
    description: "Youtube Mp3 Downloader",
    langs: ["Python"],
  },
];
