import React from "react";
import Hero from "../components/home/hero";
import About from "../components/home/about";
import Mka from "../components/home/mka";
import Services from "../components/home/services";
import Projects from "../components/home/projects";
import Contact from "../components/home/contact";
const LandingPage = () => {
  return (
    <>
      <Hero />
      <About />
      <Services />
      <Mka />
      <Projects />
      <Contact />

      {/* <div className="overflow-hidden aos-animate aos-init" data-aos="fade-down"> <Hero /></div>
      <div className="overflow-hidden aos-animate aos-init" data-aos="zoom-in" data-aos-delay="100"> <About /></div>
      <div className="overflow-hidden aos-animate aos-init" data-aos="zoom-in" data-aos-delay="100"> <Services /></div>
      <div className="overflow-hidden aos-animate aos-init" data-aos="zoom-out" data-aos-delay="100"> <Mka /></div>
      <div className="overflow-hidden aos-animate aos-init" data-aos="zoom-out" data-aos-delay="100"> <Projects /></div>
      <div className="overflow-hidden aos-animate aos-init" data-aos="zoom-in" data-aos-delay="100"> <Contact /></div> */}
    </>
  );
};

export default LandingPage;
