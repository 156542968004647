import React from "react";
import { useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Card = (props) => {
  const { name, photo, description, langs } = props;
  const [loaded, setLoaded] = useState(false);
  const originalPhotoSrc = `/assets/images/projects/${photo}`;
  const resizedPhotoSrc = `/assets/images/projects/resize/${photo}`;

  return (
    <div className="md:col-span-1 col-span-2 md:col-span-1 border-r-3 w-full border-[1px] border-uzmoon-color rounded-[1%]" >
      <LazyLoadImage
        wrapperClassName="w-full flex border-solid  items-center justify-center overflow-hidden border-b-[1px] border-uzmoon-color"
        className="object-cover transition duration-300 ease-in-out hover:scale-110 h-[10rem] object-top w-full"
        src={loaded ? originalPhotoSrc : resizedPhotoSrc}
        alt="logo"
        effect="blur"
        afterLoad={() => setLoaded(true)}
      />
      <div className="p-3">
        <div>
          <div>
            <div className="font-['SpaceGrotesk'] text-[17px] font-bold  uppercase text-main-color">
              {name}
            </div>
            <div className="font-['Inter'] text-[12px] text-uzmoon-color">
              {description}
            </div>
          </div>
        </div>
        <div className="pt-2 font-['Inter'] text-[10px] lowercase text-uzmoon-color overflow-x-auto" style={{ scrollbarWidth: 'none' }}>
          {langs && langs.length > 0 ? (
            <div className="flex space-x-2">
              {langs.map((lang, index) => (
                <div key={index} className="px-2 py-1 border-[1px] rounded">
                  #{lang}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
