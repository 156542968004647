import React from "react";
import { Navbar, MobileNav, Typography, IconButton } from "@material-tailwind/react";
import icon from "../assets/images/icon.svg";
import { Link } from "react-router-dom";

export default function Header() {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 960) setOpenNav(false);
    });
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography as="li" variant="small" color="black" className="p-1 font-normal">
        <Link to="/" className="flex items-center" onClick={() => { scrollToSection('nova'); setOpenNav(false); }}>HOME</Link>
      </Typography>
      <Typography as="li" variant="small" color="black" className="p-1 font-normal">
        <Link to="/#about" className="flex items-center" onClick={() => { scrollToSection('about'); setOpenNav(false); }}>ABOUT</Link>
      </Typography>
      <Typography as="li" variant="small" color="black" className="p-1 font-normal">
        <Link to="/#services" className="flex items-center" onClick={() => { scrollToSection('services'); setOpenNav(false); }}>SERVICES</Link>
      </Typography>
      <Typography as="li" variant="small" color="black" className="p-1 font-normal">
        <Link to="/projects" className="flex items-center" onClick={() => { scrollToSection('projects'); setOpenNav(false); }}>PROJECTS</Link>
      </Typography>
      <Typography as="li" variant="small" color="black" className="p-1 font-normal">
        <Link to="/#contact" className="flex items-center" onClick={() => { scrollToSection('contact'); setOpenNav(false); }}>CONTACT US</Link>
      </Typography>
    </ul>
  );

  return (
    <Navbar className="sticky  backdrop-blur-2xl backdrop-saturate-200 bg-opacity-80 bg-white block border border-white/80 h-max lg:px-8 lg:py-4 max-w-full px-4 py-2 rounded-none   text-black top-0 w-full z-50" fullWidth={true} shadow={false}>
      <div className="container-fluid  flex items-center justify-between text-black">

        <Typography as="a" href="#" className="mr-4 cursor-pointer py-1.5 font-medium">
          <Link to="/" onClick={() => scrollToSection('nova')}><img src={icon} className="w-[30px] h-[30px]" alt="Uzmoon Logo" /></Link>
        </Typography>
        <div className="hidden lg:block">{navList}</div>
        <Link to="/1ine1ife" className="hidden lg:inline-block btn uzmoon-btn">
          <span>1INE 1IFE</span>
        </Link>
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
          aria-label={openNav ? "Close navigation" : "Open navigation"}
        >
          {openNav ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="h-6 w-6" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          )}
        </IconButton>
      </div>
      <div>
        <MobileNav open={openNav} >
          <div className="container  ">
            {navList}
            <Link to="/1ine1ife" className="btn uzmoon-btn" onClick={() => { setOpenNav(false); }}>
              <span>1INE 1IFE</span>
            </Link>
          </div>
        </MobileNav>
      </div>
    </Navbar>
  );
}
